button.scroll-down {
  width: auto !important;
  height: auto !important;
  text-decoration: none;
  color: inherit;
  background-color: inherit;
  border: none;
  display: inline-block;
  cursor: pointer;
}

button.scroll-down img {
  width: 50px !important;
  height: 50px !important;
  float: left;
  cursor: pointer;
}

button.scroll-down h3.cta {
  margin-top: 12px;
  margin-left: 60px;
  font-size: 16pt;
  letter-spacing: 2pt;
  cursor: pointer;
}
