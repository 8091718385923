.service {
  width: 50%;
}

.service p {
  margin-right: 0;
  padding-right: 10px;
}

.service h3 {
  float: left;
  cursor: pointer;
}

.service h4 {
  margin-top: 0;
  font-weight: bold;
}

.service img.icon {
  width: 50px;
  height: 50px;
}

.service img.details {
  margin-top: 9px;
  margin-left: 10px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

@media screen and (max-width: 820px) {
  .service {
    width: 100%;
  }
}
