@font-face {
    font-family: 'Baron Neue';
    src: url('/fonts/BaronNeue-Regular.woff2') format('woff2'),
        url('/fonts/BaronNeue-Regular.woff') format('woff'),
        url('/fonts/BaronNeue-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

* {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
}

h1 {
  font-family: 'Baron Neue';
  letter-spacing: 0px;
  font-size: 6vw;
}

h2 {
  font-weight: 600;
}

h3.cta {
  font-family: 'Bebas Neue';
}

a {
  color: inherit;
  text-decoration: none;
}

.purple {
  color: #841E61;
  font-family: inherit;
}

button:focus {
  outline: 0;
}

.vcentered {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.page {
  padding-left: 80px;
}

.page > div {
  max-width: calc(100vw - 100px);
}

.page h1 {
  font-family: 'Baron Neue';
  letter-spacing: 0px;
  font-size: 6vw;
  border-bottom: 1px solid lightgray;
  display: inline-block;
  padding-right: 110px;
  margin-bottom: 5px;
  margin-top: 100px;

}

.page p {
  max-width: 600px;
  margin-bottom: 40px;
  margin-right: 50px;
}

@media screen and (max-width: 960px) {
  .page h1 {
    font-size: 40px;
    letter-spacing: 8px;
  }
}

@media screen and (max-width: 820px) {
  .page {
    padding-left: 20px;
  }

  .page h1 {
    margin-top: 50px;
    font-size: 40px;
    letter-spacing: 0px;
  }
}
