.intro {
  padding-left: 80px;
  position: relative;
}

.intro > div {
  max-width: calc(100vw - 100px);
}

.intro h1 {
  font-family: 'Baron Neue';
  border-bottom: 1px solid lightgray;
  display: inline-block;
  padding-right: 150px;
  margin-bottom: 5px;
  margin-top: 0px;
}

.intro p {
  max-width: 600px;
  margin-bottom: 40px;
}

.intro .scroll-down.contact-cta {
  border: 1px solid black;
  position: absolute;
  right: 180px;
  bottom: 40px;
}

.intro .scroll-down.contact-cta h3.cta {
  margin: 12px !important;
}

.intro img.hero {
  height: 15vh;
  min-height: 100px;
  margin-bottom: 1vh;
  margin-left: -5px;
  display: block;
}

@media screen and (max-width: 820px) {
  .intro {
    margin-top: -60px;
    padding-left: 20px;
    margin-bottom: 60px;
  }

  .intro h1 {
    font-size: 40px;
    padding-right: 120px;
  }

  .intro .scroll-down.contact-cta {
    display: none;
  }
}
