.contact {
  position: relative;
}

.contact .column {
  display: table-cell;
}

.contact .column h4 {
  font-weight: bold;
}

.contact .column p {
  margin-bottom: 0;
}

.contact .careers-cta {
  border: 1px solid black;
  position: absolute;
  right: 180px;
  bottom: 140px;
}

.contact .careers-cta h3.cta {
  margin: 12px !important;
}

@media screen and (max-width: 760px) {
  .contact .column {
    display: inline-block;
  }
}
