html, body, .paged-holster {
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
}
.paged-holster {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: column nowrap;
  width: 100vw;
}
.paged-container {
  display: flex;
  overflow-y: scroll;
  overflow-x: hidden;
  outline: 1px dashed lightgray;
  flex: none;
}
.paged-container.paged-y {
  flex-flow: column nowrap;
}
.paged-y.mandatory-scroll-snapping {
  scroll-snap-type: y mandatory;
  height: 100vh;
  width: 100%;
}
.paged-y.mandatory-scroll-nosnap {
  height: 100vh;
  width: 100%;
}
.paged-container > div {
  scroll-snap-align: center;
  flex: none;
}
.paged-y.paged-container > div {
  width: 100%;
  height: 100%;
}
