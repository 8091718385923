.pager button.pager-button .pager-dot {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: black;
  margin: 25px 0 25px 10px;
}

.pager button.pager-button .pager-dot.deactivate {
  animation-name: pager-dot-deactivate;
  animation-duration: 0.5s;
}

.pager button.pager-button .pager-active {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: black;
  margin: 25px 0 25px 8px;
  animation-name: pager-dot-activate;
  animation-duration: 0.5s;
}

.pager button.pager-button .pager-hover {
  background-color: grey !important;
}

.pager button.pager-button {
  width: 50px;
  height: 50px;
  text-decoration: none;
  color: inherit;
  background-color: inherit;
  border: none;
  display: block;
  cursor: pointer;
}

.pager button.pager-button:focus {
  outline: 0;
}

@keyframes pager-dot-activate {
  from {
    width: 4px;
    height: 4px;
    margin: 25px 0 25px 10px;
  }
  to {
    width: 8px;
    height: 8px;
    margin: 25px 0 25px 8px;
  }
}

@keyframes pager-dot-deactivate {
  from {
    width: 8px;
    height: 8px;
    margin: 25px 0 25px 8px;
  }
  to {
    width: 4px;
    height: 4px;
    margin: 25px 0 25px 10px;
  }
}

@media screen and (max-width: 678px) {
  .pager button.pager-button {
    margin-right: -45px;
  }
}
