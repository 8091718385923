.services {
  max-width: calc(100vw - 200px);
}

.services h1 {
  border-bottom: none !important;
}

.services hr {
  max-width: calc(100vw - 200px);
  margin-left: 0;
  border-top: lightgray;
}

.services .service-list {
  display: flex;
  flex-direction: row nowrap;
  height: 300px;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
}

.services .service-list > div {
  flex: none;
}

.services button {
  width: 30px;
  height: 30px;
  border: none;
  background: none;
  padding: 0;
}

.services button img {
  width: 100%;
  height: 100%;
}

.services button.left img {
  transform: rotate(180deg);
}

.services button.right {
  float: right;
}

@media screen and (max-width: 820px) {
  .services {
    max-width: 100vw !important;
  }

  .services hr {
    max-width: calc(100vw - 80px);
  }

  .services button.right {
    margin-right: 75px;
  }

}
