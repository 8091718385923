.about {
  position: relative;
}

.about .profile {
  margin-right: 180px;
  float: right;
  width: 354px;
  height: 400px;
  position: absolute;
  bottom: 0px;
  right: 20px;
}

.about .profile .container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.about .profile .portrait {
  width: 144px;
  height: 227px;
  margin: 10px;
  background-size: contain;
  background-repeat: no-repeat;
}

.about .profile img.social {
  width: 40px;
  height: 40px;
  margin: 10px 10px 10px 0;
}

.about .profile img.social.rss {
  width: 30px;
  height: 30px;
  margin: 15px 15px 15px 0;
}

.about .profile img.social.instagram {
  width: 34px;
  height: 34px;
  margin: 20px 17px 12px 0;
}

.about .profile div {
  margin: 10px;
}

.about .profile h3 {
  margin-top: 0px;
  margin-bottom: 5px;
}

.about .profile p {
  margin: 0;
}

#anders {
  background-image: url('/images/anders.jpg');
}

#gitte {
  background-image: url('/images/gitte.jpg');
}

@media screen and (max-width: 1130px) {
  .about .profile {
    margin-right: 120px;
    position: relative !important;
    bottom: auto !important;
    right: auto !important;
    float: inherit !important;
  }
}

@media screen and (max-height: 900px) {
  .about p.long {
    display: none;
  }
}

@media screen and (max-width: 760px) {
  .about .profile {
    margin-left: -20px;
    width: 340px;
    max-width: 340px;
    height: 300px;
  }

  .about .profile .portrait {
    width: 120px;
    height: 120px;
  }

  #anders {
    background-image: url('/images/anders-small.jpg');
  }

  #gitte {
    background-image: url('/images/gitte-small.jpg');
  }

}
